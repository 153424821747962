
    import {
        defineComponent
    } from 'vue';

    export default defineComponent({
        name: 'Home',

        created() {
            document.title = 'virtualwolf.xyz';
        },
    });
