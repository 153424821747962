<template>
    <header>
        <router-link v-if="this.$route.path !== '/'" :to="{path: '/'}">
            <img id="logo" alt="virtualwolf dot xyz" src="./assets/images/styles/charcoal/logo.png">
        </router-link>
        <img v-else id="logo" alt="virtualwolf dot xyz" src="./assets/images/styles/charcoal/logo.png">
    </header>

    <main>
        <router-view />
    </main>
</template>

<style lang="scss">
    @font-face {
        font-family: Montserrat;
        src: url('./assets/fonts/montserrat-500.woff2');
        font-weight: 500;
    }

    header, footer, nav, article, aside, section, main {
        display: block;
    }

    body, main, section, div, ul, li, p, h1, h2, h3, h4, h5, h6, #app {
        margin: 0;
        padding: 0;
    }

    header {
        text-align: center;
        margin-top: 5px;
    }

    body {
        font: 16px / 28px Montserrat, Helvetica, Arial;
        color: #1d1e18;
        background: url("./assets/images/styles/charcoal/background.png") top left repeat-x #f3efe0;;
        background-size: 100% 110px;
        text-align: center;
    }

    main {
        margin-top: 0.5em;
        margin: 0px auto;
    }

    main > h1 {
        margin-bottom: 0.5em;
    }

    h1, h2, h3, h4, h5, h6 {
        padding: {
            top: 0.5em;
            bottom: 0.5em;
        };
        margin: 0;
    }

    h1, h2, h3 {
        font-weight: normal;
    }

    h1 {
        font-size: 1.75em;
    }

    h2 {
        font-size: 1.4em;
    }

    h3 {
        font-size: 1.2em;
    }

    ul {
        list-style: none;
    }

    a {
        text-decoration: none;
        color: #993300;

        &:hover {
            text-decoration: underline;
        }
    }

    .small {
        font-size: 0.8em;
    }

    #logo {
        width: 250px;
        height: 100px;
        border: 0;
    }

    .media-posts {
        li {
            margin-bottom: 4em;

            p {
                padding-top: 0.5em;
                padding-bottom: 0.5em;
            }
        }
    }

    .back-link {
        padding-bottom: 2em;
    }

    .bordered-images {
        img, video {
            // padding: 1em;
            // border: solid 1px #1d1e18;
            max-width: 100%;
        }

        .individual-image + h2 {
            margin-bottom: 2em;
        }

        li:last-child {
            h2 {
                margin-bottom: 0.5em;
            }
        }

        h2 {
            padding-top: 0;
        }
    }
</style>
