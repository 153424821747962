import {
    createRouter,
    createWebHistory,
    RouteRecordRaw
} from 'vue-router';
import Home from '../views/Home.vue'

const routes: Array < RouteRecordRaw > = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/memories',
        name: 'Memories',
        component: () => import('../views/Memories.vue'),
    },
    {
        path: '/media/posts/:id(\\d+)',
        props: ['id'],
        name: 'MediaSinglePost',
        component: () => import('../views/MediaSinglePost.vue'),
    },
    {
        path: '/media/tags/:tag/:page(\\d+)?',
        props: ['tag', 'page'],
        name: 'MediaTag',
        component: () => import('../views/MediaTag.vue'),
    },
    {
        path: '/media/:page(\\d+)?',
        props: ['page'],
        name: 'media',
        component: () => import('../views/Media.vue'),
    },
    {
        path: '/media/tags',
        name: 'MediaTags',
        component: () => import('../views/MediaTags.vue'),
    },
    {
        path: '/weather',
        name: 'weather',
        component: () => import ('../views/Weather.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
